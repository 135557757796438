$react-time-range--track--valid: #34D399;
$react-time-range--track--not-valid: magenta;

.react_time_range_custom {
    padding-bottom: 2em;
    &__error {
        .react_time_range__track {
            background-color: rgba(150,0,0,.5) !important;
            border-color: #960000 !important;
        }
    }
    &__valid {
        .react_time_range__track {
            background-color: rgba(5,150,105,.5) !important;
            border-color: #059669 !important;
        }
    }
}