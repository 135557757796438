@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-gray-100;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fwag-bg {
    background-image: url('./bg.png');
    background-repeat: no-repeat;
    background-position: bottom right;
}