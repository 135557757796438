.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    line-height: 1.125em;

    button {
        outline: none;

        &:enabled {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__navigation {
        display: flex;
        height: 44px;
        margin-bottom: 1em;

        button {
            min-width: 44px;
            background: none;

            &:enabled {

                &:hover,
                &:focus {
                    @apply bg-gray-200
                }
            }

            &[disabled] {
                @apply bg-gray-100
            }
        }
    }

    &__month-view {
        &__weekdays {
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: .75em;

            &__weekday {
                padding: .5em;
            }
        }

        &__weekNumbers {

            .react-calendar__tile {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .75em;
                font-weight: bold;
                padding: calc(.75em / .75) calc(.5em / .75);
            }
        }

        &__days {
            &__day {
                &--weekend {
                    @apply text-red-dark
                }

                &--neighboringMonth {
                    @apply text-gray-400
                }
            }
        }
    }

    &__year-view,
    &__decade-view,
    &__century-view {
        .react-calendar__tile {
            padding: 2em .5em;
        }
    }

    &__tile {
        max-width: 100%;
        text-align: center;
        padding: .75em .5em;
        aspect-ratio: 1 / 1;
        background: none;
        @apply rounded-full;

        &:disabled {
            @apply text-gray-400;
        }

        &:enabled {

            &:hover,
            &:focus {
                @apply bg-gray-200
            }
        }

        &--now {
            @apply bg-red-100;

            &:enabled {

                &:hover,
                &:focus {
                    @apply bg-red-200
                }
            }
        }

        &--hasActive {
            $bgcolor: lighten(rgb(0, 110, 220), 30%);
            background: $bgcolor;

            &:enabled {

                &:hover,
                &:focus {
                    background: lighten($bgcolor, 10%);
                }
            }
        }

        &--active {
            @apply bg-red-light;
            color: white;

            &:enabled {

                &:hover,
                &:focus {
                    @apply bg-red-light;
                }
            }
        }
    }

    &--selectRange {
        .react-calendar__tile {
            &--hover {
                background-color: rgb(230, 230, 230);
            }
        }
    }
}